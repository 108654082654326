import React from 'react';
import DefaultIcon from '../img/SVG/statin-fill.svg';

export default class Feature extends React.Component {

    render()
    {
        return <div>
            <div className="w-16 mx-auto">
                {this.props.icon}
            </div>
            <h4 className={'text-xl mt-4 mb-2 font-bold'}>{this.props.title}</h4>
            {this.props.children}
        </div>
    }
};

Feature.defaultProps = {
    title: 'TITLE',
    icon: <DefaultIcon/>
};

