import styled from "@emotion/styled"
import tw from "tailwind.macro"

const Label = styled.div`
    ${tw `rounded-full inline bg-blue-100 text-blue-800 px-2 text-xs font-semibold shadow tracking-wide uppercase`};
    white-space: nowrap;
`;

export {
    Label
}
