import React from 'react';
import Layout from '../../components/layout';
import {graphql} from 'gatsby';
import Img from "gatsby-image";

import Feature from "../../components/feature";
import ComingSoon from "../../components/coming-soon";

import FaTags from "../../img/font-awesome/light/tags.svg";
import FaOrganize from "../../img/font-awesome/light/images.svg";
import FaSearch from "../../img/font-awesome/light/search.svg";
import FaZip from "../../img/font-awesome/light/file-archive.svg";
import FaTransfer from "../../img/font-awesome/light/person-carry.svg";
import FaConvert from "../../img/font-awesome/light/exchange.svg";

import DownloadButton from "../../components/download-button";
import HeroBackground from "../../components/hero-background";

export default class IndexPage extends React.Component {

    render()
    {
        return (

            <Layout lang={'de'}
                    title={'2stitch Organizer - Stickdateien anschauen und verwalten'}
                    description={'Gratis Programm zum Verwalten Deiner Stick-Dateien auf Windows PCs und Macs. Zeigt Stickdateien von Bernina, Brother, Husqvarna, Pfaff, Janome und mehr.'}
                    translated={{en:'/'}}
                    location={this.props.location.pathname}
            >

                <div className={'leading-normal text-lg'}>
                    <HeroBackground img={this.props.data.heroImage.childImageSharp.fluid}>
                        <div className="container mx-auto text-center">
                            <h1 className="text-xl md:text-5xl text-blue-50 font-medium mb-3 ">2stitch
                                Organizer</h1>
                            <p className="text-md md:text-3xl text-blue-50">
                                Stickdateien einfach sortieren, anschauen und finden<br/>
                                Gratis für Windows &amp; macOS
                            </p>

                            <DownloadButton linkMac={'/de/download-organizer-mac/'}
                                            linkWindows={'/de/download-organizer-windows/'}/>

                        </div>
                    </HeroBackground>


                    <div className="bg-blue-500 text-blue-50 pb-12">
                        <div className={"container mx-auto"}>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Stickdateien am Computer anschauen"} icon={<FaOrganize
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Betrachte Deine Stick-Dateien auf deinem Computer.
                                            2stitch Organizer zeigt Dir alle wichtigen Informationen
                                            wie Größe, Anzahl der Stiche und verwendete Garne an.
                                        </Feature>
                                    </div>

                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Finde die passende Stickdatei"} icon={<FaSearch
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Durchsuche Deine Stickdatei-Sammlung nach Größe, Schlagworten, Beschreibung oder Dateiformat.
                                        </Feature>
                                    </div>

                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Schlagworte (Tags)"} icon={<FaTags
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Ordne deine Stickdateien nach Schlagworten, z.B. Blumen,
                                            Monogramme, Weihnachten, Vintage, &hellip;

                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Einfaches Arbeiten mit Zip-Dateien"} icon={<FaZip
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Du brauchst keine zip-Dateien mehr zu entpacken. 2stitch Organizer
                                            kann den Inhalt der Dateien einfach darstellen.
                                            <br/><ComingSoon/>
                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Übertragung zur Stickmaschine"} icon={<FaTransfer
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Kopiere ausgewählte Stickdateien mit nur einem Klick auf deinen
                                            USB-Stick.
                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Format-Umwandlung"} icon={<FaConvert
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Wandle Stickdateien, die nicht für deine Stickmaschine bestimmt
                                            sind in das passende Format um.
                                            <br/><ComingSoon/>
                                        </Feature>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={''}>

                        <div className="container mx-auto pt-12 pb-4 ">

                            <div className="section-header">
                                <h2>Wie sieht 2stitch Organizer aus?</h2>
                                <p>
                                    2stitch Organzier hat eine einfach zu bedienende Oberfläche,
                                    die Dir eine gute Übersicht über deine
                                    Stickdateien bietet.
                                </p>
                            </div>

                            <div className="container mx-auto">

                                <Img fluid={this.props.data.screenShotImage.childImageSharp.fluid}/>

                            </div>
                        </div>
                    </div>

                    <div className="bg-blue-50">

                        <div className="container mx-auto pt-12 pb-12">

                            <div className="section-header">
                                <h2>Unterstützt Dateien für alle gebräuchlichen Stickmaschinen</h2>
                                <p>
                                    2stitch Organizer zeigt Dateien in den folgenden Formaten an
                                </p>
                            </div>

                            <div className="row mt-4">


                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Poem, Huskygram, Singer * </td>
                                            <td className={'w-1/6 xl:w-auto'}>CSD</td>
                                        </tr>
                                        <tr>
                                            <td>Tajima</td>
                                            <td>DST</td>
                                        </tr>
                                        <tr>
                                            <td>ZSK *</td>
                                            <td>DSZ</td>
                                        </tr>
                                        <tr>
                                            <td>Melco</td>
                                            <td>EXP</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>

                                        <tr>
                                            <td>Husqvarna/Viking</td>
                                            <td className={'w-1/6 xl:w-auto'}>HUS</td>
                                        </tr>
                                        <tr>
                                            <td>Janome/Elna/Kenmore</td>
                                            <td>JEF</td>
                                        </tr>
                                        <tr>
                                            <td>Pfaff *</td>
                                            <td>PCS</td>
                                        </tr>
                                        <tr>
                                            <td>Babylock, Brother, Deco</td>
                                            <td>PEC, PES</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Janome, Elna, Kenmore</td>
                                            <td className={'w-1/6 xl:w-auto'}>SEW</td>
                                        </tr>
                                        <tr>
                                            <td>Happy *</td>
                                            <td>TAP</td>
                                        </tr>
                                        <tr>
                                            <td>Husqvarna Viking, Pfaff *</td>
                                            <td>VIP</td>
                                        </tr>
                                        <tr>
                                            <td>Compucon, Singer</td>
                                            <td>XXX</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={'text-center mt-2'}>
                            * = wird bald unterstützt
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-blue-500 pt-12 pb-12 text-blue-50">
                    <div className="container mx-auto">

                        <div className="section-header">
                            <h2>... und zum Schluß</h2>
                        </div>

                        <div className={'row flex-wrap leading-normal text-lg'}>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Stick-Simulation</h4>
                                <p>
                                    Der Stick-Simulator zeigt Dir, wir die Datei gestickt wird
                                    bevor Du sie auf die Stickmaschine überträgst.
                                </p>
                                <ComingSoon/>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Realistische Vorschau</h4>
                                <p>
                                    Die realistische Vorschau zeigt Dir, wie die Stick-Datei aussehen wird,
                                    wenn sie gestickt ist.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Weitere Formate </h4>
                                <p>
                                    In Zukunft werden wir auch folgenge Datei-Formate unterstützen:<br/>

                                    .CND,
                                    .CSD, .DST, .DSZ, .EMB, .EMD,
                                    .EXP, .HUS, .JAN, .JEF,
                                    .PCS, .PCM, .PES, .PHB,
                                    .PHC, .PHD, .SEW, .SHV,
                                     .VP3
                                </p>
                                <ComingSoon/>
                            </div>
                        </div>

                    </div>

                </div>


                <Img fluid={this.props.data.footerImage.childImageSharp.fluid}/>

            </Layout>
        )
            ;
    }
}


export const pageQuery = graphql`
query {

    heroImage: file(relativePath: { eq: "parallax-2.jpg" }) {
      ...fluidImage
    },

    screenShotImage: file(relativePath: { eq: "organizer/screenshot-mac.png" }) {
      ...fluidImage
    },

    footerImage: file(relativePath: { eq: "threads.jpg" }) {
      ...fluidImage
    }
  }
`;
